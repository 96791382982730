@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.featured-products {
  background-color: transparent;
  padding-left: calc(6vw);
  padding-right: calc(6vw );
}

.section-title {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #202020;
}

.featured-product-card {
  border: transparent;
  border-radius: 0;
  transition: transform 0.3s ease;
  margin-right: 50px;
  margin-left:65px;
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;
   /* Adjust this value to control the spacing */
  /*flex: 0 0 calc(25% - 10px); !* 5 items per row with spacing *!*/
  min-width: calc(25% + 100px); /* Minimum card width */
  
}
.featured-product-card:hover {
  transform: translateY(-5px);
}
.featured-product-card img{
  max-width: 200px;
  height: 450px;
}
.featured-product-card .btn-primary:hover {
  background-color: #333;
}

.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
}
.carousel::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
.featured-product-image {
  width: calc(7vw + 200px);
  
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent !important;
  border: none !important;
  color: black;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.carousel-control:hover,
.carousel-control:focus,
.carousel-control:active {
  background: transparent !important;
  border: none !important;
  outline: none;
  color: black;
}

.carousel-control.left {
  left: -15px;
}

.carousel-control.right {
  right: -15px;
}

.featured-product-info {
  margin-top: 10px;
}

.featured-product-title {
  font-family: 'inter';
  font-size: 15px;
  color: black;
  margin: 0;
  text-align: left;
  margin-left: 10%;
}

.featured-product-price {
  font-family: 'inter';
  font-size: 0.875rem;
  color: black;
  margin: 5px 0 0 0;
  text-align: left;
  margin-left: 22%;
}

@media (max-width: 990px) {
  .featured-products {
    padding-left: 15px;
    padding-right: 15px;
  }

  .featured-products .carousel {
    padding-left: 15px;
  }

  .featured-product-card {
    margin-right: 4px;
    margin-left: 4px;
    min-width: calc(33.333% - 8px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .carousel-container {
    padding: 0;
  }

  .featured-product-card img {
    max-width: 100%;
    height: 140px;
    object-fit: contain;
    margin-left: 5%;
  }

  .featured-product-image {
    width: 100%;
    display: block;
  }

  .featured-product-info {
    width: 100%;
    text-align: left;
    margin-top: 5px;
  }

  .featured-product-title {
    font-size: 10px;
    margin-left: 5%;
    text-align: left;
  }

  .featured-product-price {
    font-size: 10px;
    margin-left: 15%;
    text-align: left;
  }

  .product-col {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-left: 2px;
    padding-right: 2px;
  }

  .carousel-control {
    display: none;  /* Hide the carousel controls on mobile */
  }
}

/* Add specific adjustments for smaller phones */
@media (max-width: 375px) {
  .featured-product-card img {
    height: 150px; /* Even smaller for very small screens */
  }
}
