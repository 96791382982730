/* shoppingCart.css */

.shopping-cart-container {
  margin-top: 2rem;
  font-family: 'Lato', sans-serif;
  color: #464646;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: calc(6vw - 2px);
  padding-right: calc(6vw - 2px);
  gap: 20px;
}
.cart-for-you{
  padding-left: calc(6vw );
}
/*.two-boxes{*/
/*  display: flex;*/
/*  justify-content: flex-start;*/
/*  align-items: flex-start;*/
/*  width: 100%;*/
/*  left: 0;*/
/*  margin-left: 0;*/
/*  margin-right: 0;*/
/*}*/

.shopping-bag-title {
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 2.3%;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0px;
  word-wrap: break-word;

}

.cart-item {
  overflow: hidden;
  margin-bottom: 1rem;
  display: flex;
  border-bottom: 1px solid #EDEDED;
  padding: 1rem 0;
  justify-content: flex-start;
}

.cart-item .line{
  margin-bottom: 30px;
  margin-top: 11px;
}

.cart-img {
  display: flex;
  align-items: flex-start;
  width: 100px;
  height: auto;
  object-fit: cover;
}


.img-details{
  display: flex;
  justify-content: flex-start;
}

.title-price{
  display: flex;
  justify-content: flex-start;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 100;
}
.split-qL{
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.item-title,
.item-size,
.item-quantity {
  margin: 0;
  padding: 0.25rem 0;
}

.item-details {
  padding-left: 40px;
  margin-left: 1rem;
  flex-grow: 1;
}
/*.item-quantity{}*/
.item-size{
  margin-top: 110px;
}

.item-title {
  font-size: 1rem;
  font-weight: 500;
}

.item-links {
  display: flex;
  gap: 0.5rem;
  padding-left: 40%;
}

.item-links button {
  font-size: 0.875rem;
  color: #464646;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.item-links button:hover {
  color: #e3d8d8;
}

.item-price {
  font-size: 15px;
  font-weight: 550;
  margin-left: 40%;
}

/* order summary*/

.checkout-button {
  background-color: #000;
  color: #fff;
  width: 100%;
  max-width: 359px;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 0px;
  transition: background-color 0.3s ease;
  margin-top: 5px;
}

.checkout-button:hover {
  background-color: #333;
}

.list-group-item {
  border: none;
  padding: 0;
}

.order-card {
  outline: none;
  border: white;
  box-shadow: none;
  position: relative;
  top: -35px;
  width: 100%;
  max-width: 400px;
  margin-right: -15px;
  margin-left: auto;
}

.order-card-title {
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 6%;
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 500;
  word-wrap: break-word;

}

.order-card-total,
.order-card-shipping,
.order-total-amt {
  font-family: 'Inter';
  color: black;
  font-size: 15;
  font-weight: 500;
  margin-top: 10px;
  margin-left: auto;
  padding-left: 15px;
}
.order-total{
  font-family: 'Inter';
  color: black;
  font-size: 15px;
  font-weight: 800;
  word-wrap: break-word;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.order-card-body{
  width: 100%;
  max-width: 400px;
  overflow: visible;
}
.order-total-amt{
  font-family: 'Inter';
  color: black;
  font-size: 15px;
  font-weight: 600;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
  margin-left: auto;
}
.order-margin{
  margin-top: 37px;
}

.card-text {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.tot-ship-card{
  margin-top: 10px;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@media screen and (max-width: 768px) {
  .shopping-cart-container {
    padding: 0 20px;
    flex-direction: column;
    position: relative;
  }

  .cart-for-you {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }

  .cart-for-you h2,
  .cart-for-you .section-title {
    padding-left: 0;
    margin-left: 0;
  }

  .cart-for-you .products-grid,
  .cart-for-you .row {
    margin-left: 0;
    padding-left: 0;
  }

  .shopping-bag-title {
    font-size: 20px;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    width: 90%;
    padding-bottom: 15px;
    position: relative;
  }

  .shopping-bag-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #D9D9D9;
    display: block !important;
    z-index: 1;
  }

  .shopping-bag-title::before {
    display: none !important;
  }

  /* Cart Item Styling */
  .cart-item {
    flex-direction: row;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    width: 90%;
  }

  .cart-img {
    width: 100px;
    height: 125px;
    object-fit: contain;
    margin-right: 15px;
  }

  .img-details {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: -7%;
  }

  .item-details {
    padding-left: 15px;
    margin: 0;
    width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
  }

  .item-title {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-right: 80px;
    margin: 0;
    line-height: 1.2;
    padding-top: 10px;
  }

  .item-size,
  .item-quantity {
    font-size: 12px;
    color: #757575;
    margin: 2px 0;
    padding: 0;
    line-height: 1.2;
  }

  .item-price {
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 10px;
    width: auto;
    white-space: nowrap;
    margin: 0;
    line-height: 1.2;
  }

  .item-links {
    padding-left: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .item-links button {
    font-size: 12px;
    color: #464646;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  /* Order Summary Styling */
  .order-card {
    position: static;
    width: 100%;
    margin: 20px auto;
    padding: 0;
    box-shadow: none;
    border-top: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .order-card-title {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }

  .tot-ship-card {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }

  .order-total,
  .order-total-amt,
  .order-card-shipping {
    font-size: 12px;
  }

  .checkout-button {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  /* Remove the bottom padding since order summary is not fixed */
  .shopping-cart-container {
    padding-bottom: 20px;
  }

  .order-card-body {
    width: 100%;
  }

  /* Ensure the details wrapper is properly positioned */
  .img-details {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  /* Remove any existing division lines */
  .shopping-cart-container::before,
  .shopping-cart-container::after,
  .cart-for-you::before,
  .cart-for-you::after {
    display: none;
  }

  /* Keep only the subtle lines between items */
  .cart-item {
    flex-direction: row;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    width: 90%;
  }

  /* Remove any border from the last item if needed */
  .cart-item:last-child {
    border-bottom: none;
  }

  /* Remove any border from the order card if it exists */
  .order-card {
    border-top: none;
  }

  /* Remove the line after the title */
  .shopping-bag-title::after,
  .shopping-bag-title::before {
    display: none !important;
  }

  /* Remove any horizontal rules or dividers */
  .shopping-cart-container hr,
  .cart-for-you hr,
  .cart-item .line {
    display: none !important;
  }

  /* Ensure proper spacing in the details section */
  .item-details {
    padding-left: 15px;
    margin: 0;
    width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
  }

  .split-qL {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 8px !important;
  }

  .item-quantity {
    margin: 0;
    padding: 0;
  }

  .item-links {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 0;
  }

  /* Style the separator between Save for Later and Remove */
  .item-links span {
    margin: 0 4px;
  }

  /* Target the first Col in shopping cart container */
  .shopping-cart-container > Col:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }

  /* Ensure ListGroup takes full width of the centered Col */
  .shopping-cart-container .list-group {
    width: 100%;
  }

  /* Keep existing cart item styles */
  .cart-item {
    flex-direction: row;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    width: 100%; /* Changed from 90% since parent is now centered */
  }

  /* Target the first Col in shopping cart container */
  .shopping-cart-container > Col:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }

  /* Ensure the title takes full width and centers properly */
  .shopping-bag-title {
    font-size: 20px;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    width: 90%;
    padding-bottom: 15px;
    position: relative;
  }

  /* Add the line under the title */
  .shopping-bag-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #D9D9D9;
    display: block !important;
    z-index: 1;
  }

  /* Remove any potential margins from parent containers */
  .shopping-cart-container {
    padding: 0 20px;
    flex-direction: column;
    position: relative;
  }

  /* Make first Col match second Col width in mobile only */
  .shopping-cart-container > Col:first-child {
    width: 100%;
    max-width: 33.333333%; /* Equivalent to col-4 */
    flex: 0 0 33.333333%;
    margin: 0 auto;
  }

  .shopping-bag-title {
    font-size: 20px;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    width: 90%;
    padding-bottom: 15px;
    position: relative;
  }

  .shopping-bag-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #D9D9D9;
    display: block !important;
    z-index: 1;
  }

  /* Make first Col match second Col dimensions exactly */
  .shopping-cart-container > Col:first-child {
    width: 350px !important;
    margin: 0 auto;
    transform: translateX(20px);
  }

  .shopping-bag-title {
    font-size: 20px;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    width: 100%;
    padding-bottom: 15px;
    position: relative;
  }

  .shopping-bag-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #D9D9D9;
    display: block !important;
    z-index: 1;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .cart-item {
    padding: 15px;
  }

  .cart-img {
    width: 90px;
    height: 112px;
  }

  .item-details {
    width: calc(100% - 105px);
  }
}
