.hero-carousel {
  position: relative;
  width: 100%;
  margin-top: 200px; /* Add space between navbar and carousel */
  overflow: hidden;
}

.hero-block {
  margin-top: 50px;
  width: 100%;
  padding-left: calc(6vw);
  padding-right: calc(6vw + 10px);
}

.carousel-item {
  max-height: 80%; /* Adjust the height of the carousel item as needed */
}

.carousel-image {
  object-fit: cover; /* Ensures the image covers the area */
  width: 100%; /* Full width */
  height: 80%; /* Full height */
}

.centerCap {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  top: 60%; /* Position of the caption */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0); /* Change this number to add the background */
  padding: 1rem;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
}

.centerCap p {
  font-family: 'Lato', sans-serif;
  font-size: 1vw !important;
  margin-bottom: 1.5rem;
  color: #fff; /* White text color */
  padding-left: 50px;
}

.carousel-caption h2 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 3.8vw;
  margin-bottom: 1rem;
  color: #f0f0f0;
}

.carousel-caption p {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #fff; /* White text color */
}

/* New section: Button group for alignment */
.button-group {
  display: flex;
  justify-content: space-between; /* Align buttons on the same line, left and right */
  align-items: center; /* Center buttons vertically */
  padding: 1rem 0; /* Adds vertical padding */
  width: 100%; /* Ensures full-width alignment */
  margin-top: 20%;
}

.mensButton, .womensButton {
  font-size: 1.2vw;
  color: #fff;
  flex: 1; /* Allows buttons to grow equally */
}

.carousel-caption .btn-primary {
  background-color: transparent;
  color: #fff; /* White text color */
  padding: 0.75rem 1.5rem; /* Padding for the button */
  border: none; /* No border */
  border-radius: 0; /* No rounded corners */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
  text-decoration: underline; /* Underline text */
  outline: none; /* Remove outline */
}
.carousel-caption .btn-primary:focus,
.carousel-caption .btn-primary:active {
  outline: none !important; /* Ensure outline is removed on focus and active */
  box-shadow: none !important; /* Remove any box shadow */
}
.carousel-caption .btn-primary:hover {
  transform: translateY(-3px); /* Slight lift effect on hover */
}

.carousel-caption .btn-primary i {
  margin-left: 0.5rem; /* Space between text and icon */
}


@media (max-width: 768px) {
  .hero-block {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .carousel-item {
    height: 60vh; /* Adjust height relative to viewport */
  }

  .carousel-item img {
    height: 100%;
    object-fit: cover;
  }

  .centerCap {
    top: 50%;
    width: 90%; /* Give more width on mobile */
    white-space: normal; /* Allow text to wrap */
  }

  .carousel-caption h2 {
    font-size: 24px; /* Fixed size for mobile */
    margin-bottom: 0.5rem;
  }

  .centerCap p {
    font-size: 14px !important;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  .button-group {
    margin-top: 1rem;
    gap: 20px; /* Add space between buttons */
    padding: 0;
  }

  .mensButton, .womensButton {
    font-size: 14px;
    padding: 8px 16px;
    white-space: nowrap;
  }

  /* Adjust carousel controls for better mobile visibility */
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }
}

/* Add specific adjustments for very small screens */
@media (max-width: 375px) {
  .carousel-item {
    height: 50vh;
  }

  .carousel-caption h2 {
    font-size: 20px;
  }

  .centerCap p {
    font-size: 12px !important;
  }

  .mensButton, .womensButton {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 990px) {
  .hero-block {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .carousel-item {
    aspect-ratio: 1/1;  /* Makes it square */
    width: 100%;
    max-height: 300px;  /* Reduced from 375px to 300px */
  }

  .carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .centerCap {
    top: 50%;
    width: 90%;
    white-space: normal;
  }

  .carousel-caption h2 {
    font-size: 24px;
    margin-bottom: 0.5rem;
  }

  .centerCap p {
    font-size: 11px !important;
    padding-left: 0;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  .button-group {
    margin-top: 1rem;
    gap: 20px;
    padding: 0;
  }

  .mensButton, .womensButton {
    font-size: 14px;
    padding: 8px 16px;
    white-space: nowrap;
  }
}
