.for-you-products {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: left;
}

.for-you-products-section-title {
  font-family: 'Arial', sans-serif;
  font-weight: 550;
  font-size: 1rem;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #202020;
  text-align: left !important; /* Force align title to the left */
  
}

.for-you-products-grid::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.for-you-products-grid {
  display: flex;
  justify-content: left;
  flex-wrap: nowrap; /* Ensure items do not wrap */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.for-you-product-card {
  flex: 0 0 auto; /* Ensure cards do not shrink or grow */
  width: 250px; /* Set a fixed width for the cards */
  box-sizing: border-box;
  margin: 0 10px; /* Add margin to each side of the card */
  
}
.for-you-product-card:hover{
  transform: translateY(-1.5px);
}
.for-you-product-image {

  object-fit: cover;
  max-width: 150px;
  height: 250px;
}

.for-you-product-info {
  margin-top: 10px;
}

.for-you-product-title {
  font-family: 'inter';
  font-size: 12px;
  color: black;
  margin: 0;
  text-align: left;
  margin-left: 5%;
}

.for-you-product-price {
  font-family: 'inter';
  font-size: 12px;
  color: black;
  margin: 5px 0 0 0;
  text-align: left;
  margin-left: 15%;
}

@media (max-width: 990px) {
  .for-you-product-card {
    width: 200px;  /* Slightly smaller cards for tablets */
  }

  .for-you-product-image {
    max-width: 120px;
    height: 200px;
  }
}

@media (max-width: 400px) {
  .for-you-product-card {
    width: 150px;  /* Much smaller cards for phones */
  }

  .for-you-product-image {
    max-width: 100px;
    height: 150px;
  }

  .for-you-product-title {
    font-size: 11px;
  }

  .for-you-product-price {
    font-size: 11px;
  }

  /* Adjust section padding */
  .for-you-products {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .for-you-products-section-title {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
