.sidebar {
  width: 350px;
  padding: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h3 {
  font-family: 'DM Sans-Regular', Helvetica;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}

.filter-section div {
  margin-bottom: 10px;
  font-family: 'DM Sans-Regular', Helvetica;
}

.filter-section input {
  margin-right: 10px;
  font-family: 'DM Sans-Regular', Helvetica;
}

h2 {
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #333;
  font-family: 'DM Sans-Regular', Helvetica;
}

.reset-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  color: #2e2e30;
  cursor: pointer;
  text-align: right;
  font-size: 14px;
  text-decoration: underline;
}

.reset-button:hover {
  color: #d6caca;
}

.label {
  font-size: 14px;
  color: #333;
}
