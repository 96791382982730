.reviews-section {
  padding: 40px 0;
  margin: 40px 6vw;
  margin-left: 2vw;
  border-top: 1px solid #eee;
}

.reviews-section h2 {
  font-family: "Questrial", sans-serif;
  margin-bottom: 30px;
}

.review-form {
  max-width: 600px;
  margin-bottom: 40px;
}

.review-form h3 {
  font-family: "Questrial", sans-serif;
  margin-bottom: 20px;
}

.rating-input {
  margin-bottom: 20px;
}

.review-form textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  font-family: "Questrial", sans-serif;
}

.submit-review {
  background-color: #000;
  color: white;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  font-family: "Questrial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.review-item {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.review-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.review-username {
  font-weight: 500;
  font-family: "Questrial", sans-serif;
}

.verified-badge {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-family: "Questrial", sans-serif;
}

.review-comment {
  margin-bottom: 10px;
  font-family: "Questrial", sans-serif;
  line-height: 1.5;
}

.review-date {
  color: #666;
  font-size: 0.9em;
  font-family: "Questrial", sans-serif;
}

.MuiRating-icon {
  color: #000000 !important;
}

/* For the empty/unfilled stars */
.MuiRating-iconEmpty {
  color: rgba(0, 0, 0, 0.3) !important;
}

.size-fit-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  font-family: "Questrial", sans-serif;
  font-size: 0.9em;
}

.size-fit-bar {
  position: relative;
  width: 200px;
  height: 4px;
  background-color: #eee;
  border-radius: 2px;
}

.size-fit-indicator {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.size-label {
  color: #666;
  font-size: 0.8em;
}

/* Mobile adjustments */
@media (max-width: 990px) {
  .reviews-section {
    margin: 20px 15px;
    margin-left: 5px;
    padding: 20px 0;
  }

  .review-form {
    width: 100%;
    padding: 0 15px;
  }

  .reviews-list {
    padding: 0 15px;
  }

  .review-item {
    padding: 15px 0;
  }

  .size-fit-bar {
    width: 150px;
  }
}

.size-fit-input {
  margin-bottom: 20px;
}

.size-fit-input h4 {
  font-family: "Questrial", sans-serif;
  font-size: 1em;
  margin-bottom: 10px;
}

.size-fit-bar.clickable {
  cursor: pointer;
}

/* Optional: Add hover effect */
.size-fit-bar.clickable:hover {
  background-color: #f5f5f5;
} 