.user-page-container {
  margin-top: 2rem;
  font-family: 'Lato', sans-serif;
  color: #464646;
}

.user-detail-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid #ededed;
}

.user-detail-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.user-detail-value {
  margin-bottom: 0.5rem;
}

.user-detail-item .btn-link {
  font-size: 1.25rem;
  color: #464646;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.user-detail-item .btn-link:hover {
  color: #007bff;
}

.custom-tabs .nav-link {
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid transparent;
}

.custom-tabs .nav-link.active {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.custom-tabs .nav-link:hover {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
