.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust spacing as needed */
  margin: 0 auto;
  padding: 20px;
}

.product-card {
  flex: 0 1 22%; /* Adjusts the basis to slightly less than 25% to accommodate margin/padding */
  margin: 10px;
  /* Other styles */
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 3fr));
  gap: 16px;
  padding: 16px;
}
