@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet');

.products-description-store {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background-color: #fff;

}
.number-sort-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.products-sort-store{
    margin-left: auto;
    margin-right: -6.3vw;
}
.text-section-store {
    flex: 1;
    padding-top: 120px;
    padding-right: 5%;
}
.number-products{
    font-family: "Lato", sans-serif;
    font-size: 24px;
    white-space: nowrap;
    margin-left: 15px;
    overflow: hidden;
}
.title-header-store {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 70px;
}

.title-description {
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: #333;
}

.img-store {
    padding-top: 70px;
}

.img-store img {
    height: auto;
    border-radius: 8px;
    width: 40vw;
}
.store-page-container {
    padding-right: 6vw;
    padding-left: 6vw;
}

@media screen and (max-width: 768px) {
  .store-page-container {
    padding: 0 15px;
    overflow-x: hidden;
  }

  .products-description-store {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .img-store {
    width: 80%;
    padding-top: 0;
    margin-bottom: 20px;
    order: -1 !important;
    margin-left: auto;
    margin-right: auto;
  }

  .img-store img {
    width: 100% !important;
    height: auto !important;
    border-radius: 8px;
    max-width: 300px;
    display: block;
    margin: 0 auto;
  }

  .text-section-store {
    width: 100%;
    padding: 0 !important;
    text-align: center;
    order: 2 !important;
  }

  .title-header-store {
    font-size: 20px !important;
    text-align: center;
    margin: 15px 0 10px;
    letter-spacing: 0.05em;
    font-weight: 400;
  }

  .title-description {
    font-size: 10px !important;
    line-height: 1.4;
    text-align: center;
    padding: 0 40px;
    margin-bottom: 25px;
    color: #464646;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    white-space: normal;
  }

  /* Products sort section */
  .number-sort-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  .number-products {
    display: none;
  }

  .products-sort-store {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .Form-control {
    height: 20px !important;
    width: 110px !important;
    padding: 2px 6px !important;
    border: 1px solid #e0e0e0 !important;
    font-size: 10px !important;
    font-family: Lato !important;
    color: #464646 !important;
    font-weight: 400 !important;
    background-color: white !important;
    display: block !important;
    line-height: normal !important;
    min-height: 0 !important;
  }

  /* Product grid */
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 0;
    margin: 20px 0;
  }

  .product-card {
    width: 100%;
    margin: 0;
  }

  .product-card img {
    width: 85%;
    height: auto;
    object-fit: contain;
  }
}
