@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap" rel="stylesheet');

@media (max-width: 990px) {
  /* */
  .product-page-container {
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  .image-container {
    position: relative;
    width: 50%;
  }

  /* Container modifications */
  .product-page-container > div:first-child > div {
    display: flex !important;
    flex-direction: column !important;
  }

  /* Set order for mobile layout */
  .image-container {
    order: 1;
    width: 50% !important;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .image-options {
    order: 2;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
  }

  .image-options img {
    width: 60px !important;
    height: 60px !important;
    object-fit: contain !important;
    border: 1px solid #eee;
  }

  /* Force image size */
  .product-image {
    width: 100% !important;
    max-width: 300px !important;
    height: auto !important;
    object-fit: contain !important;
  }

  /* For even smaller screens */
  @media (max-width: 480px) {
    .image-container {
      width: 85% !important;
    }
  }

  /* Hide arrows for all mobile sizes */
  .left-arrow,
  .right-arrow {
    display: none !important;
  }

  /* Style for dropdowns */
  .custom-dropdown {
    border-radius: 0 !important;
  }

  .dropdown-toggle {
    border-radius: 0 !important;
  }

  .dropdown-menu {
    border-radius: 0 !important;
  }

  .dropdown-item {
    border-radius: 0 !important;
  }

  /* Style for Add to Cart button */
  .product-page-container Button {
    border-radius: 0 !important;
  }
}

@media (min-width: 991px) and (max-width: 18000px) {
  .product-page-container { 
    margin-left: 6vw;
    
    margin-top: 5%;
  }
  .image-container {
    
    position: relative;
    max-width: 300px;
  }

  .product-details {
    flex: 1;
    
    
  }

  .custom-dropdown {
    min-width: 50%;
    text-align: left;
  }
  .details {
    padding: 50%;
  }
  .product-image {
    width: 300px;
    height: 35vw;
    display: block;
    margin-right: 10vw;
  }
  .dark-button {
    min-width: 50%;
    margin-top: 10%;
    min-height: 6%;
  }

  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 80%; /* Adjust size as needed */
    color: white; /* Adjust color as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Adjust background as needed */
    padding: 10px; /* Adjust padding as needed */
    user-select: none;
  }

  .left-arrow {
    left: 0;
  }

  .right-arrow {
    right: 0;
    
  }

  .image-container:hover .left-arrow,
  .image-container:hover .right-arrow {
    display: block;
  }

  .left-arrow,
  .right-arrow {
    display: none;
  }
  .image-options {
    margin-right: 10vw;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  
  .text-muted{
    font-family: "Questrial", sans-serif;
    font-size:1.5em; /* Adjust font size as needed */
    font-weight: 500;
    color: #1b1b1b !important; /* Dark color for better readability */
    margin: 0; /* Adds some space above and below the title */
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5); /* Optional: adds a subtle shadow for better contrast */
  
  }
  .productPrice{
    font-family: "Questrial", sans-serif;
    font-size: 1.25em;
    padding-bottom: 45px;
  }
  .selectDrop{
    font-family: "Questrial", sans-serif;
    font-size: 1em;
    color: #1b1b1b;
    font-weight: 500;
  }


  .size-select {
    display: block; /* Ensures the select fills the width of its container */
    width: 80%; /* Adjust width as needed */
    margin: 10px auto; /* Centers the select horizontally and adds space above and below */
    padding: 5px; /* Adds some padding inside the select box for better readability */
    border: 1px solid #ddd; /* A light border for the dropdown */
    border-radius: 4px; /* Rounds the corners of the select box */
    background-color: #f8f8f8; /* A light background color for the select */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* An inner shadow for some depth */
    cursor: pointer; /* Changes the cursor to indicate this is an interactive element */
  }

  /* Style for options within the select */
  .size-select option {
    padding: 5px; /* Adds some padding for options for easier reading */
  }
  .product-page-container Button{
    border-radius: 0 !important;
  }
  /* Hover and focus styles for the select element to improve user interaction */
  .size-select:hover,
  .size-select:focus {
    border-color: #bbb; /* Darkens the border on hover/focus for visual feedback */
    background-color: #f0f0f0; /* Changes background to a slightly darker shade */
  }
}
@media (min-width: 2001px) and (max-width: 20000px) {
  .product-page-container {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }

  .image-container {
    position: relative;
    width: 50%;
  }
}

/* Mobile styles (max-width: 990px) */
@media (max-width: 990px) {
  .product-page-container {
    margin: 20px 15px;  /* Smaller margins for mobile */
  }

  /* Layout adjustments */
  .product-page-container > div {
    flex-direction: column !important;  /* Stack elements vertically */
  }

  /* Image section */
  .image-container {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .product-image {
    width: 100% !important;
    max-width: 300px !important;
    height: auto !important;
  }

  /* Thumbnail navigation */
  .image-options {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
    margin: 0;
    position: relative;
  }

  .image-options img {
    width: 60px !important;
    height: 60px !important;
    object-fit: contain !important;
    border: 1px solid #eee;
  }

  /* Navigation arrows */
  .left-arrow,
  .right-arrow {
    position: static !important; /* Override the absolute positioning */
    transform: none !important;
    display: flex !important;
    align-items: center;
    padding: 10px;
    background: none;
    color: #000;
  }
  .left-arrow,
  .right-arrow {
    display: none !important;
  }

  /* Product details */
  .product-details {
    padding: 20px;
    text-align: center;
  }

  /* Size and color dropdowns */
  .custom-dropdown {
    width: 100% !important;
    margin: 10px 0;
  }
}

@media (max-width: 440px) {
  .image-container {
    width: 60% !important;
    margin-right: auto;
    margin-left: 20%;
  }

  .product-image {
    max-width: 150px !important;
  }

  /* Hide arrows on mobile */
  .left-arrow,
  .right-arrow {
    display: none !important;
  }
}
