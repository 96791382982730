.signup-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40% !important;
}

.signup-box-admin {
  width: 50% !important;
}
