@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet');


.title-price {

  margin-left: 0;

}
.product-card:hover {
  transform: translateY(-5px);
}
.product-card .card-title {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: black;
  margin: 0;
  text-align: center;
  margin-top: 15px;

}
.brand-price {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  color: black;
  font-weight: normal;
  margin: 5px 0 0 0;
  text-align: center;

}
.featured-product-info {
  margin-top: 10px;
}
.product-card img {
  object-fit: scale-down;
}
.product-card {
  border: transparent;
  border-radius: 0;
  transition: transform 0.3s ease;
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;

}
.product-card .card-body {
  background-color: white;
  overflow: hidden !important;
}
