/* Footer.css */
.footer {
  background-color: #ffffff;
  color: #464646;
  padding: 2rem 0;
  font-size: 1rem;
  position: relative;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.footer-logo {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 32px;
  color: #202020;
}

.footer-center nav {
  display: flex;
  gap: 24px;
}

.footer-center nav a {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: #464646;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-center nav a:hover {
  color: #000000;
}

.footer-right {
  display: flex;
  gap: 16px;
}

.social-icon {
  font-size: 20px;
  color: #464646;
  text-decoration: none;
  border: 1px solid #c8c8c8;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon:hover {
  color: #000000;
  border-color: #000000;
}

.footer-bottom {
  text-align: center;
  padding: 1rem 0;
  border-top: 1px solid #ededed;
  margin-top: 1rem;
}

.footer-bottom p {
  margin: 0;
  color: #c8c8c8;
}

@media (max-width: 990px) {
  .footer {
    padding: 1rem 0;
  }

  .footer-content {
    flex-direction: column;
    gap: 20px;
    padding: 1rem 15px;
    margin: 0;
    max-width: 100%;
  }

  .footer-logo {
    font-size: 24px;
    padding-left: 15px;
  }

  .footer-center nav {
    gap: 16px;
    padding-left: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .footer-center nav a {
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  .footer-center nav a:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-right {
    gap: 12px;
    padding-left: 15px;
  }

  .social-icon {
    font-size: 16px;
    width: 36px;
    height: 36px;
  }

  .footer-bottom {
    padding: 1rem 15px;
    margin-top: 1rem;
    text-align: left;
  }

  .footer-bottom p {
    font-size: 12px;
    padding-right: 15px;
  }
}
