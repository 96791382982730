@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap" rel="stylesheet');


.title{
    font-family: "DM Sans", sans-serif;
    text-align: center;
    margin-top: 80px;
    font-size: 2.5rem;
    letter-spacing: 0.05em;
    font-weight: 400;
}

.h3tags{
    color: black;
    font-family: "DM Sans", sans-serif;
}
.bcountries{
    font-weight: 600 !important;
    display: inline;
}
.boldedWord{
    
    color: black;
    font-family: "DM Sans", sans-serif;
    margin-top: 3vw;
    
    
}
.description{
    color: #464646;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: center;
    max-width: 800px;
    margin: 40px auto 80px;
    padding: 0 20px;
}
.descriptionA{
    color: black;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    
}
.disclaimer{
    margin-top: 4vw;
    color: black;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    text-align: center;
}
.bullets{
   
    color: black;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
}


.accordion{
    width:75%;
    margin-left: 15%;
    margin-top: 6vw;
    margin-bottom: 7vw;
    
}
.accordion-button{
    
    border: none;
    font-family: "DM Sans", sans-serif;
    font-size: 2.5rem; 
}
.accordion-button:focus{
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}

@media screen and (max-width: 768px) {
  .accordion-button {
    font-size: 16px !important;
    padding: 12px 15px;
  }
  
  .accordion-button:not(.collapsed) {
    font-size: 16px !important;
  }

  .title {
    font-size: 20px;
    margin-top: 40px;
  }

  .description {
    font-size: 12px;
    line-height: 1.5;
    margin: 20px auto 40px;
    padding: 0 25px;
  }
}