@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet');
.products-content {
  display: flex;
  margin-top: 2%;
  margin-left: -1%;
}
.title-header {
  font-family: 'Josefin Sans', sans-serif;
}
.filter-section {
  margin-bottom: 20px;
}
.products-list-container {
  flex: 1;
}
.number-sort-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 6vw;
}

.products-sort{
  margin-left: auto;
  margin-right: -6vw;
}
.num-products{
  font-family: "Lato", sans-serif;
  font-size: 24px;
  margin-left: 7vw;
  white-space: nowrap;
  overflow: hidden;
}
.Form-control{
  height: 44px;
    width: 240px;

    padding-left: 10px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;

    border: 1px #b1a8a8 solid;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;

    font-size: 18px;
    font-family: Lato;
    color: #837a7a;
    font-weight: 400;
    line-height: 20;
    letter-spacing: 0.28;
    word-wrap: break-word;

    box-shadow: none;
    margin-right: 10px;
    background-color: #ffffff;
    outline: none;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust spacing as needed */
  margin: 0 auto;
}
.products-list-container{
  margin-top: 13px;
}
.productsPage {
  padding-left: 6vw;
  padding-right: 6vw;
}
.breadcrumb-container {
  background-color: white;
  padding: 10px 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  list-style: none;

}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding: 0 8px;
  color: #bbb8b8;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #bbb8b8;
  transition: color 0.2s;
}

.breadcrumb-item a:hover {
  color: #000;
}

.breadcrumb-item.active {
  color: #000;
  font-weight: 500;
}

@media (min-width: 991px) and (max-width: 1100px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 220px);
    gap: 16px;
  }
  .product-card img {
    max-height: 300px;
    max-width: 220px;
  }
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 220px);
    gap: 16px;
  }
  .product-card img {
    max-height: 320px;
    max-width: 220px;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 240px);
    gap: 16px;
  }
  .product-card img {
    max-height: 340px;
    max-width: 240px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 260px);
    gap: 16px;
  }
  .product-card img {
    max-height: 360px;
    max-width: 260px;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    gap: 16px;
  }
  .product-card img {
    max-height: 380px;
    max-width: 280px;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 16px;
  }
  .product-card img {
    max-height: 400px;
    max-width: 300px;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 320px);
    gap: 16px;
  }
  .product-card img {
    max-height: 420px;
    max-width: 320px;
  }
}

@media (min-width: 1701px) and (max-width: 1800px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 340px);
    gap: 16px;
  }
  .product-card img {
    max-height: 440px;
    max-width: 340px;
  }
}

@media (min-width: 1801px) and (max-width: 1900px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 360px);
    gap: 16px;
  }
  .product-card img {
    max-height: 460px;
    max-width: 360px;
  }
}

@media (min-width: 1901px) and (max-width: 2000px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 380px);
    gap: 16px;
  }
  .product-card img {
    max-height: 480px;
    max-width: 380px;
  }
}

@media (min-width: 2001px) and (max-width: 2100px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    gap: 16px;
  }
  .product-card img {
    max-height: 500px;
    max-width: 400px;
  }
}

@media (min-width: 2101px) and (max-width: 2200px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 420px);
    gap: 16px;
  }
  .product-card img {
    max-height: 520px;
    max-width: 420px;
  }
}

@media (min-width: 2201px) {
  .img-products-hero img {
    padding-right: 55px;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
  }
  .title-header {
    margin-top: 30px;
    font-size: 60px;
  }
  .product-card {
    margin: 10px;
  }
  .product-list {
    display: grid;
    grid-template-columns: repeat(3, 440px);
    gap: 16px;
  }
  .product-card img {
    max-height: 540px;
    max-width: 440px;
  }
}

@media screen and (max-width: 768px) {
  /* Container and general layout */
  .productsPage {
    padding: 0 15px;
    overflow-x: hidden;
  }

  /* Breadcrumb adjustments */
  .breadcrumb-container {
    padding: 10px 0 10px 0;
    margin-left: 0;
  }

  .breadcrumb {
    margin: 0;
    padding-left: 4px;
    margin-bottom: 20px;
  }

  .breadcrumb-item {
    font-size: 11px;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding: 0 4px;
  }

  /* Header and description */
  .productsPage .products-description {
    padding: 0;
    margin: 15px 0;
  }

  .productsPage .title-header {
    font-size: 20px !important;
    text-align: center;
    margin: 15px 0 10px;
    letter-spacing: 0.05em;
    font-weight: 400;
  }

  .productsPage .title-description {
    font-size: 10px !important;
    line-height: 1.4;
    text-align: center;
    padding: 0 40px;
    margin-bottom: 25px;
    color: #464646;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .productsPage .products-description p {
    font-size: 10px !important;
  }

  .productsPage .title-description br {
    display: none;
  }

  /* Hero image */
  .img-products-hero {
    display: none;
  }

  /* Products section */
  .products-content {
    display: block;
  }

  /* Filter and sort section */
  .number-sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .products-sort {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .filter-button {
    height: 20px;
    padding: 2px 8px;
    border: 1px solid #e0e0e0;
    background-color: white;
    font-size: 10px;
    font-family: Lato;
    color: #464646;
    font-weight: 400;
    display: block !important;
    text-transform: none;
  }

  .Form-control {
    height: 20px;
    width: 110px;
    padding: 2px 6px;
    border: 1px solid #e0e0e0;
    font-size: 10px;
    font-family: Lato;
    color: #464646;
    font-weight: 400;
    background-color: white;
    display: block !important;
  }

  /* Style for the dropdown options */
  .Form-control option {
    background: #f5f5f5;
    padding: 10px;
  }

  /* Hide products count on mobile */
  .num-products {
    display: none;
  }

  /* Style the dropdown */
  .Form-control {
    height: 44px;
    width: 240px;
    padding-left: 10px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px #b1a8a8 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    font-size: 18px;
    font-family: Lato;
    color: #837a7a;
    font-weight: 400;
    line-height: 20;
    letter-spacing: 0.28;
    word-wrap: break-word;
    box-shadow: none;
    margin-right: 10px;
    background-color: #ffffff;
    outline: none;
  }

  /* Hide desktop filter sidebar */
  .filter-sidebar {
    display: none;
  }

  /* Product grid */
  .products-list-container {
    width: 100%;
    margin: 0;
  }

  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 0;
    margin: 20px 0;
    transform: translateX(12px);
  }

  .product-card {
    width: 100%;
    margin: 0;
  }

  .product-card img {
    width: 85%;
    height: auto;
    object-fit: contain;
    transform: translateX(7px);
  }

  /* Add styles for product description and price */
  .product-card .product-name {
    font-size: 10px;
    margin-top: 8px;
  }

  .product-card .product-price {
    font-size: 10px;
    margin-top: 4px;
  }

  /* Number and sort container */
  .number-sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .num-products {
    font-size: 14px;
    margin: 0;
  }

  /* Add filter button next to sort dropdown */
  .products-sort {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .filter-button {
    display: none;
  }

  .Form-control {
    border: none;
    font-size: 14px;
    color: #464646;
    padding-right: 15px;
    background: transparent;
    cursor: pointer;
  }

  /* Hide the filter button from FilterSidebar component */
  .filter-sidebar .filter-button {
    display: none;
  }

  /* Hide desktop filter sidebar when in mobile filter panel */
  .filter-sidebar-mobile .filter-button {
    display: none;
  }

  /* Hide the desktop filter sidebar in mobile */
  .products-content > .filter-sidebar {
    display: none;
  }

  /* Show it only on mobile */
  .filter-button {
    display: block;
    font-size: 14px;
    color: #464646;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  /* Hide ALL filter sidebars in mobile */
  .filter-sidebar,
  .filter-sidebar-mobile,
  .products-content > .filter-sidebar {
    display: none !important;
  }

  /* Target specifically within product-card to avoid affecting other pages */
  .product-card .card-title {
    font-size: 10px !important;
    margin-top: 8px;
  }

  .product-card .brand-price {
    font-size: 10px !important;
    margin-top: 4px;
  }

  /* Pagination styles */
  .pagination {
    scale: 0.5; /* Reduced from 0.8 to 0.5 (50% of original size) */
  }

  .pagination .page-link {
    padding: 2px 6px; /* Further reduced padding */
    font-size: 10px; /* Smaller font size */
  }

  .pagination .page-item {
    margin: 0 1px; /* Tighter spacing between numbers */
  }

  .products-sort {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  .filter-button {
    height: 44px;
    padding: 10px 20px;
    border: 1px solid #b1a8a8;
    background-color: white;
    font-size: 18px;
    font-family: Lato;
    color: #837a7a;
    font-weight: 400;
    display: block !important; /* Force display */
  }

  .Form-control {
    height: 44px;
    width: 240px;
    padding: 10px;
    border: 1px solid #b1a8a8;
    font-size: 18px;
    font-family: Lato;
    color: #837a7a;
    font-weight: 400;
    background-color: white;
    display: block !important; /* Force display */
  }

  /* Make selectors more specific to ProductsPage */
  .productsPage .products-sort {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    width: 100% !important;
    position: relative !important;
  }

  .productsPage .number-sort-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .productsPage .filter-button {
    height: 20px !important;
    padding: 2px 8px !important;
    border: 1px solid #e0e0e0 !important;
    background-color: white !important;
    font-size: 10px !important;
    font-family: Lato !important;
    color: #464646 !important;
    font-weight: 400 !important;
    display: block !important;
    text-transform: none !important;
    line-height: normal !important;
    min-height: 0 !important;
  }

  .productsPage .Form-control {
    height: 20px !important;
    width: 110px !important;
    padding: 2px 6px !important;
    border: 1px solid #e0e0e0 !important;
    font-size: 10px !important;
    font-family: Lato !important;
    color: #464646 !important;
    font-weight: 400 !important;
    background-color: white !important;
    display: block !important;
    line-height: normal !important;
    min-height: 0 !important;
  }

  .productsPage .products-sort {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 15px !important;
    width: 100% !important;
    transform: translateX(-15px) !important; /* Shift 5px to the left */
  }

  .productsPage .number-sort-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

/* Hide the filter button by default (desktop) */
.filter-button {
  display: none;
}

@media screen and (max-width: 768px) {
  /* Show the filter button only on mobile */
  .filter-button {
    display: block !important;
    height: 20px !important;
    padding: 2px 8px !important;
    border: 1px solid #e0e0e0 !important;
    background-color: white !important;
    font-size: 10px !important;
    font-family: Lato !important;
    color: #464646 !important;
    font-weight: 400 !important;
    text-transform: none !important;
  }
  
}
