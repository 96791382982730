.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #f0f0f0;
}

.pagination button:disabled {
  color: #aaa;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: #2e2e30;
  color: #fff;
  border-color: #2e2e30;
}
