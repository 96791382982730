.input-box {
  background-color: #ffffff;
  color: #000000;
}

.input-box:focus {
  background-color: #ffffff;
  caret-color: #000000;
  color: #000000;
}

.input-box:focus::placeholder {
  opacity: 1;
}

.social-login-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 10px; /* Space between icons */
  padding: 10px;
  border: none;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 1.5rem;
}

.social-login-button i {
  margin: 0; /* Remove margin */
}

.social-login-button.google {
  background-color: #db4437;
}

.social-login-button.facebook {
  background-color: #3b5998;
}

.social-login-button.twitter {
  background-color: #1da1f2;
}

.social-login-button.linkedin {
  background-color: #0077b5;
}

.link {
  position: relative;
  color: rgb(67, 186, 255);
  text-decoration: none;
}

.link:hover {
  color: rgb(255, 60, 60);
}

.link::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.link:hover::before {
  transform: scaleX(1);
}

.link-color-white:hover {
  color: white;
}

.header {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.header:hover {
  color: black;
}

#review-range {
  -webkit-appearance: none;
  background: transparent;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-runnable-track {
  background: #2e2e2e;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ff7755;
}

/******** Firefox ********/
input[type='range']::-moz-range-track {
  background: #252525;
}

input[type='range']::-moz-range-thumb {
  border: none;
  background-color: #ff9955;
}

#comments {
  height: 200px;
}

.or-login-text {
  text-align: center;
  margin-top: 20px;
  font-size: 1.5rem; /* Increase the font size */
  font-weight: 400; /* Make the text bold */
}
