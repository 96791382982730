@media (max-width: 990px) {

  .title1{
    font-family: 'Josefin Sans';
    font-size: 35px;
    font-weight: inherit;
    
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .title2 {
    font-family: 'Josefin Sans';
    font-size: 15px;
    margin-bottom: 30px;
  }
  
  .discover-div {
    margin-top: 2px;
    text-align: center;
  }
  .explore-section {
    text-align: center;
    margin-top: 20px;
  }
  .section-title .explore {
    font-family: 'Arial', sans-serif;
    font-size: 30px;
  }
  .section-title .more {
    font-family: 'Arial', sans-serif;
    font-size: 10px; 
  }
  .categories {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-left: 5%;
  }

  .category {
    font-family: 'Arial', sans-serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 10px;
  }
  .description {
    margin-top: 4%;
    font-family: 'Arial', sans-serif;
    font-size: 10px !important;
    color: #666;
    margin-left: 25%;

  }
}




@media (min-width: 990px) {
  .title1 {
    font-family: 'Josefin Sans';
    font-size: 65px;
    font-weight: inherit;
    
  }
  .title2 {
    font-family: 'Josefin Sans';
    font-size: 25px;
    margin-bottom: 50px;
  }

  .discover-div .title1 {
    padding-left: calc(6vw - 10px);
    margin-bottom: 0;
  }
  .discover-div .title2 {
    padding-left: calc(18vw);
    
    margin-top: 0;
  }
  .discover-div {
    margin-top: 20px;
    
  }

  .explore-section {
    text-align: center;
    padding: 40px 20px;
    margin-bottom: 50px;
  }

  .section-title {
    
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adjust the gap as needed */
    margin-bottom: 30px;
  }

  .section-title .explore {
    font-family: 'Arial', sans-serif;
    font-size: 4rem;
    margin: 0;
    padding-left: calc(6vw - 750px);
  }

  .section-title .more {
    font-family: 'Arial', sans-serif;
    font-size: 1.5rem;
    margin: 0;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
  }

  .categories {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .category {
    font-family: 'Arial', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 10px;
  }

  .description {
    margin-top: 4%;
    font-family: 'Arial', sans-serif;
    font-size: 0.875rem;
    color: #666;
    line-height: 1.5;
    margin-left: 150px;
    margin-right: 150px;
  }

  .featured-products-container {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding: 0 40px;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }

  .slick-list {
    margin: 0 auto;
    width: 100%;
  }
}

/* Add this media query for screens between mobile and 1400px */
@media (min-width: 990px) and (max-width: 1400px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr); /* Force 3 columns */
    gap: 15px; /* Slightly reduce gap if needed */
  }

  .product-card {
    width: 100%; /* Make cards fill their grid space */
    min-width: 0; /* Allow cards to shrink if needed */
  }

  .product-image {
    width: 100%;
    height: auto;
  }
}
