/* CheckoutPage.css */
.checkout-container {
  padding: 2rem 0;
}

.checkout-form {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.checkout-form h4 {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
}

.place-order-btn {
  width: 100%;
  background-color: #000;
  border: none;
}

.order-summary {
  position: sticky;
  top: 2rem;
}

.order-summary .card-title {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
.input-field {
  max-width: 50%;
}

/* Add additional styles based on your design requirements */
