/* NavBar.module.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');


@media (max-width: 990px) {
  .topNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #ffffff;
    position: relative;
  }

  .leftNav {
    width: 33%;
  }

  .navlogo {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    width: 33%;
  }

  .rightNav {
    width: 33%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }

  .citySelectorContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .citySelector {
    width: 33%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }

  .citySelector .formControl {
    width: 120px;
    height: 36px;
    padding: 8px 32px 8px 8px;
    font-size: 12px;
    background-size: 14px;
    background-position: right 8px center;
  }

  .mobileNav {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #ffffff;
  }

  .navLink {
    padding: 4px 0;
    padding-left: 16px;
    margin-left: 15px;
    margin-right: 16px;
    font-size: 13px;
    color: #202020;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    transition: color 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navLink::after {
    content: '→';
    font-size: 14px;
    color: #202020;
    opacity: 0.7;
  }

  .navLink:active {
    color: #666;
  }
}

@media (min-width: 991px) and (max-width: 15000px) {
  /* NavBar.module.css */

  .topNavbar,
  .bottomNavbar {
    font-family: 'Inter', sans-serif;
    font-size: small;
    position: relative;
    background: #ffffff;
    z-index: 1000;
  }

  .topNavbar {
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
    height: 92px;
  }

  .bottomNavbar {
    margin-top: -1px; /* to avoid double borders */
    height: 76px;
    border-bottom: 1px solid #ededed;
    box-shadow: 0 4px 10px 0 #ededed;
  }

  .navLink {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #464646;
    margin-right: 32px;
    word-wrap: break-word;
    font-family: 'Lato', sans-serif;
    text-align: center;
  }
  .active {
    visibility: visible;
    text-decoration: underline;
    text-underline-offset: 26px;
    text-decoration-thickness: 2px;
    text-decoration-color: #464646;
  }
  .navLink::before {
    visibility: visible;
    text-decoration: underline;
    text-underline-offset: 26px;
    text-decoration-thickness: 2px;
    text-decoration-color: #464646;
  }

  .navLink:hover {
    text-decoration: underline;
    text-underline-offset: 26px;
    text-decoration-thickness: 2px;
    text-decoration-color: #464646;
  }

  .navlogo {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #202020;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
  }

  .searchInputGroup {
    border: 1px solid #c8c8c8; /* Visible border */

    overflow: hidden;

    height: 44px;
    align-items: center;

    margin-right: 3%;
  }

  .searchInput {
    border: none;
    box-shadow: none;
    background-color: #ffffff;
    outline: none;
    padding-left: 36px;
    padding-bottom: 10px;
  }

  .searchIcon {
    background-color: #ffffff;
    border: none;
    position: absolute;
    padding-right: 0;
    padding-left: 16px;
    color: #717171;
    padding-bottom: 10px;
  }

  .labelMaker {
    padding-left: 2rem;
    align-items: center;
    display: inline-flex;
    font-weight: 400;
    font-size: 1rem;
  }

  .formControl {
    height: 44px;
    width: 190px;

    padding-left: 10px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;

    border: 1px #202020 solid;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;

    font-size: 14px;
    font-family: Lato;
    font-weight: 400;
    line-height: 20;
    letter-spacing: 0.28;
    word-wrap: break-word;

    box-shadow: none;
    margin-right: 10px;
    background-color: #ffffff;
    outline: none;
  }
  .leftNav {
    padding-left: calc(6vw);
  }
  .leftBottomNav {
    padding-left: calc(6vw - 10px);
  }
  .leftNav,
  .rightNav {
    display: flex;
    align-items: center;
  }
  .rightNav {
    padding-right: 6vw;
  }
  .iconLink {
    padding: 10px;
    margin: 0 8px;
    border: 1px solid #c8c8c8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 44px;
  }

  .icon {
    color: #464646;
    width: 20px;
    height: 20px;
  }
}

.combinedNavbar {
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menuToggle {
  border: none;
  padding: 0;
}

.navlogo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  font-size: 24px;
  color: #202020;
}

/* Mobile menu styling */
.mobileNav {
  padding: 20px 0;
}

.navLink {
  padding: 12px 0;
  font-size: 16px;
  color: #202020;
}

.formControl {
  font-size: 14px;
  border: none;
  background: transparent;
}

/* Base styles for the select element */
.selectBorder {
  border: 1px solid #202020 !important;
  border-radius: 0;
}

.formControl {
  height: 44px;
  width: 190px;
  padding: 10px 40px 10px 10px;
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  line-height: 20px;
  background-color: #ffffff;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

/* Mobile styles */
@media (max-width: 990px) {
  .citySelectorContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
  }

  .citySelector {
    width: 33%;
  }

  .citySelector .formControl {
    width: 120px;
    height: 36px;
    padding: 8px 32px 8px 8px;
    font-size: 12px;
    background-size: 14px;
    background-position: right 8px center;
  }
}
